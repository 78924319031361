<template>
  <div class="d-flex justify-content-center align-items-center">
    <Card class="wmin-350 wmin-md-450" :with-footer="false">
      <template v-slot:default>
        <div class="text-center mb-3">
          <Logo class="mb-3 mt-1" :is-ico="true" />
          <span class="d-block text-muted">{{$t('general.forgot.subtitle')}}</span>
        </div>
        <div class="login-form-container">
          <div class="form-group form-group-feedback form-group-feedback-left">
            <TextInput @input="setEmail" :reference="user" :value="user.getEmail()" :placeholder="$t('general.forgot.username')" v-bind:class="v$.user.email.$error && 'error'"/>
            <div class="form-control-feedback">
              <i class="icon-user text-muted"></i>
            </div>
            <div class="input-errors" v-for="(error, index) of v$.user.email.$errors" :key="index">
              <span class="input-error text-danger text-wrap w-50">{{ getValidationError(error.$validator) }}</span>
            </div>
          </div>
          <div v-if="step==1">
            <div class="form-group form-group-feedback form-group-feedback-left" v-bind:class="v$.user.forgotToken.$error && 'form-group-error'">
              <TextInput @input="setToken" :reference="user" :value="user.getForgotToken()" :placeholder="$t('general.forgot.token')" v-bind:class="v$.user.forgotToken.$error && 'error'"/>
              <div class="form-control-feedback">
                <i class="icon-lock2 text-muted"></i>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.user.forgotToken.$errors" :key="index">
                <span class="input-error text-danger text-wrap">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group form-group-feedback form-group-feedback-left" v-bind:class="v$.user.password.$error && 'form-group-error'">
              <TextInput @input="setPassword" :reference="user" :value="user.getPassword()" type="password" :placeholder="$t('general.forgot.password')" v-bind:class="v$.user.password.$error && 'error'"/>
              <div class="form-control-feedback">
                <i class="icon-lock2 text-muted"></i>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.user.password.$errors" :key="index">
                <span class="input-error text-danger text-wrap">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
            <div class="form-group form-group-feedback form-group-feedback-left" v-bind:class="v$.user.copassword.$error && 'form-group-error'">
              <TextInput @input="setCoPassword" :reference="user" :value="user.getCoPassword()" type="password" :placeholder="$t('general.forgot.copassword')" v-bind:class="v$.user.copassword.$error && 'error'"/>
              <div class="form-control-feedback">
                <i class="icon-lock2 text-muted"></i>
              </div>
              <div class="input-errors" v-for="(error, index) of v$.user.copassword.$errors" :key="index">
                <span class="input-error text-danger text-wrap">{{ getValidationError(error.$validator) }}</span>
              </div>
            </div>
          </div>
          <div v-show="canShowNotification" class="form-group">
            <NotificationAlert :notifications="notification" />
          </div>
          <div class="form-group">
            <Button v-if="step == 0" v-bind:disabled="!user.isChanged()" @click="generateToken" type="confirm" :name="$t('general.forgot.buttons.generate')" class="w-100"/>
            <Button v-else v-bind:disabled="!user.isChanged() || !isFormReady" @click="reset" type="confirm" :name="$t('general.forgot.buttons.reset')" class="w-100"/>
          </div>
          <div class="text-center">
            <router-link :to="{name: 'general.breadcrumb.users.login'}">
              {{ $t('general.forgot.buttons.login') }}
            </router-link>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "@/components/elements/Card";
import Logo from "@/components/elements/Logo";
import TextInput from "@/components/elements/TextInput";
import Button from "@/components/elements/Button";
import {LoadingModalMixin} from "@/mixins/ModalMixin";
import User from "@/entities/User";
import {AuthorizationMixin} from "@/mixins/GeneralMixin";
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import {ValidationsMixin, NotificationsMixin} from "@/mixins/GeneralMixin";
import NotificationAlert from "@/components/elements/NotificationAlert";
const sameAsPassswordValidator = (value, vm) => vm.user.password === value;

export default {
  setup () {
    return { v$: useVuelidate() }
  },
  name: "UserForgotPassword",
  components: {NotificationAlert, Button, TextInput, Logo, Card},
  mixins: [LoadingModalMixin, AuthorizationMixin, ValidationsMixin, NotificationsMixin],
  validations: {
    user: {
      email: {
        required: required,
        email: email,
      },
      forgotToken: {
        required,
      },
      password: {
        required,
      },
      copassword: {
        required,
        sameAsPassword: sameAsPassswordValidator,
      }
    },
  },
  computed: {
    isFormReady: function () {
      return !(
          this.v$.user.email.$error ||
          this.v$.user.password.$error ||
          this.v$.user.copassword.$error ||
          this.v$.user.forgotToken.$error
      );
    }
  },
  data: function () {
    return {
      user: new User(),
      step: 0,
    }
  },
  methods: {
    setPassword: function (user, value)
    {
      user.setPassword(value);
      this.v$.user.password.$touch();
    },
    setCoPassword: function (user, value)
    {
      user.setCoPassword(value);
      this.v$.user.copassword.$touch();
    },
    setEmail: function (user, value)
    {
      user.setEmail(value);
      this.v$.user.email.$touch();
    },
    setToken: function (user, value)
    {
      user.setForgotToken(value);
      this.v$.user.forgotToken.$touch;
    },
    generateToken: function () {
      let that = this;
      this.resetGlobalNotifications();

      this.v$.user.email.$touch();
      if (!this.isFormReady) {
        return;
      }

      let data = {
        email: this.user.getEmail(),
      }

      this.queueJob();
      this.axios.put(this.$store.state.config.getRecoverPasswordUri(), data).then(
          () => {
            that.step = 1;
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.addGlobalSuccessNotification("general.forgot.generate_success");
        that.completeJob();
      });
    },
    reset: function () {
      let that = this;
      this.resetGlobalNotifications();

      this.v$.user.email.$touch();
      this.v$.user.password.$touch();
      this.v$.user.copassword.$touch();
      this.v$.user.forgotToken.$touch();

      if (!this.isFormReady) {
        return;
      }

      let data = {
        email: this.user.getEmail(),
        password: this.user.getPassword(),
        token: this.user.getForgotToken(),
      }

      this.queueJob();
      this.axios.put(this.$store.state.config.getRecoverPasswordUri(), data).then(
          () => {
            that.$router.push({name: 'general.breadcrumb.users.login'});
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        that.step = 0;
        that.addGlobalSuccessNotification("general.forgot.reset_success");
        that.completeJob();
      });
    },
  }
}
</script>

<style scoped>
img {
  height: 4rem;
}
.login-form-container {
  min-width: 17rem;
}
</style>